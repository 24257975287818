.area-topo-image {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.area-topo-image--canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

