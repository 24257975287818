@charset "utf-8";
@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/functions.sass";
@import "~bulma/sass/utilities/derived-variables.sass";
@import "~sweetalert2/src/sweetalert2.scss";
@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';
@import '~leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';

$footer-background-color: $scheme-main;

body {
  background-color: $scheme-main-bis;
}

@import
  'styles/flex',
  'styles/table',
  'styles/text',
  'styles/cursor',
  'styles/radio',
  'styles/whitespace';

@import "~bulma/bulma";

#sapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}
