  #canvas-container {
    position: relative;
  }

  #canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #canvas svg {
    width: 100%;
    height: 100%;
  }
