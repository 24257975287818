.is-flex {
  display: flex !important;
}

.is-flex-row {
  flex-direction: row !important;
}

.is-flex-row-revered {
  flex-direction: row-reverse !important;
}

.is-flex-column {
  flex-direction: column !important;
}

.is-flex-column-revered {
  flex-direction: column-reverse !important;
}

.is-justified-start {
  justify-content: flex-start !important;
}

.is-justified-center {
  justify-content: center !important;
}

.is-justified-end {
  justify-content: flex-end !important;
}

.is-justified-around {
  justify-content: space-around !important;
}

.is-justified-between {
  justify-content: space-between !important;
}

.is-aligned-start {
  align-items: flex-start !important;
}

.is-aligned-center {
  align-items: center !important;
}

.is-aligned-end {
  align-items: flex-end !important;
}
